import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { API_BASE_URL } from '../constants';
import MovieCard from '../MovieCard';
import './SearchPage.css';

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 1) {
        fetchSearchResults(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = async (query) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/movies/search?query=${encodeURIComponent(query)}`);
      if (!response.ok) {
        throw new Error('Search request failed');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    if (term.length === 4) {
      posthog.capture('searching', {"term": term});
    } else if (term.length === 7) {
      posthog.capture('searching', {"term": term});
    }
    setSearchTerm(e.target.value);
  };

  const handleMovieSelect = (movie) => {
    posthog.capture('clicked_movie_from_search', {"search_term": searchTerm, "movie": movie.name, });
    navigate(`/movie/${movie.tmdb_id}`);
  };

  return (
    <div className="search-page">
      <div className="search-container">
        <div className="search-input-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Type movie name in english..."
            autoFocus
          />
        </div>
      </div>
      
      {isLoading ? (
        <div className="loading">
          <p>Searching...</p>
        </div>
      ) : searchResults.total > 0 ? (
        <div className="search-results-grid">
          {searchResults.movies.map((movie) => (
            <MovieCard
              key={movie.id}
              movie={movie}
              onClick={() => handleMovieSelect(movie)}
            />
          ))}
        </div>
      ) : searchTerm.length > 0 ? (
        <div className="no-results">
          <p>No movies found</p>
          {/[\u0D00-\u0D7F]/.test(searchTerm) && (
            <p>Please type in English</p>
          )}
        </div>
      ) : (
        <div className="search-prompt">
          <p>Start typing to search movies</p>
        </div>
      )}
    </div>
  );
};

export default SearchPage; 