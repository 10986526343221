// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

// Generate an anonymous user ID if not already set
if (!localStorage.getItem('anonymous_id')) {
  localStorage.setItem('anonymous_id', `anon_${Math.random().toString(36).substring(2)}`);
}

if (process.env.REACT_APP_ENV !== "local") {
  // Initialize PostHog
  posthog.init("phc_Pkqne60xk24boPZoVPv5BK9VKSLxwTT0S46BQY2Pha8", {
    api_host: "https://us.i.posthog.com",
    persistence: "localStorage",
  });

  // Identify user with anonymous ID
  posthog.identify(localStorage.getItem('anonymous_id'), {
    $set_once: {
        first_seen: new Date().toISOString(),
    },
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PostHogProvider 
      client={posthog}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);