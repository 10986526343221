import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoSettingsOutline, IoHeartOutline, IoSearch, IoHomeOutline } from 'react-icons/io5';
import './SidebarNav.css';

const SidebarNav = ({ isSmartTV }) => {
  const location = useLocation();
  const isMovieDetailPage = location.pathname.includes('/movie/');

  if (isMovieDetailPage) {
    return null;
  }

  // Don't render at all if not a Smart TV
  if (!isSmartTV) {
    return null;
  }

  return (
    <div className="sidebar-nav">
      <div className="sidebar-content">
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
          <IoHomeOutline />
        </Link>
        <Link to="/search" className={location.pathname === '/search' ? 'active' : ''}>
          <IoSearch />
        </Link>
        <Link to="/later" className={location.pathname === '/later' ? 'active' : ''}>
          <IoHeartOutline />
        </Link>
        <Link to="/settings" className={location.pathname === '/settings' ? 'active' : ''}>
          <IoSettingsOutline />
        </Link>
      </div>
    </div>
  );
};

export default SidebarNav; 