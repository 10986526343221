import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoSettingsOutline, IoHeartOutline, IoSearch, IoHomeOutline } from 'react-icons/io5';
import './BottomNav.css';

const BottomNav = ({ isSmartTV }) => {
  const location = useLocation();
  const isMovieDetailPage = location.pathname.includes('/movie/');

  // Don't render the bottom nav if we're on a movie detail page or on a Smart TV
  if (isMovieDetailPage || isSmartTV) {
    return null;
  }

  return (
    <nav className="bottom-nav">
      <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
        <IoHomeOutline />
        <span>Home</span>
      </Link>
      <Link to="/search" className={location.pathname === '/search' ? 'active' : ''}>
        <IoSearch />
        <span>Search</span>
      </Link>
      <Link to="/later" className={location.pathname === '/later' ? 'active' : ''}>
        <IoHeartOutline />
        <span>Watch Later</span>
      </Link>
      <Link to="/settings" className={location.pathname === '/settings' ? 'active' : ''}>
        <IoSettingsOutline />
        <span>Settings</span>
      </Link>
    </nav>
  );
};

export default BottomNav; 