// src/MovieDetail.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './constants';
import ExpandableText from './components/ExpandableText';
import './MovieDetail.css';
import './layout.css'; // Import the new CSS file
import posthog from 'posthog-js';
import { IoArrowBackOutline, IoHeart, IoHeartOutline, IoVolumeMuteOutline, IoExpand, IoPlayBack, IoPlayForward } from "react-icons/io5";
import { saveMovieTime, getMovieTime } from './utils/watchHistory';

const MovieDetail = ({ isSmartTV }) => {
  const [movie, setMovie] = useState(null);
  const [playerError, setPlayerError] = useState(null);
  const [cleaningText1, setCleaningText1] = useState('');
  const [cleaningResponse1, setCleaningResponse1] = useState('');
  const [cleaningText2, setCleaningText2] = useState('');
  const [cleaningResponse2, setCleaningResponse2] = useState('');
  const [cleaningResponse3, setCleaningResponse3] = useState('');
  const playerRef = useRef(null);
  const timeCheckIntervalRef = useRef(null);
  const { tmdbId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { movies, currentIndex } = location.state || { movies: [], currentIndex: -1 };
  const [isWatchLater, setIsWatchLater] = useState(false);
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isIOS =
        /iPad|iPhone|iPod/.test(ua) ||
        // iPadOS 13+ returns 'MacIntel' but has touch support
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const [isMuted, setIsMuted] = useState(false);
  


  function getStrategy(movie) {
    if (movie.like_count === null || movie.view_count === null) {
        return 3;
    }
    let choice = (movie.like_count + movie.view_count) % 10
    if (choice < 3)
        return 1
    else if (choice >= 3 && choice <= 6)
        return 2
    else
        return 3
  }

  function hexDecode(hex) {
    return hex.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
  }
  
  function deobfuscateYoutubeId(obfuscatedId, strategy) {
    let youtubeId;
    const decodedId = hexDecode(obfuscatedId);
  
    if (strategy === 1) {
      youtubeId = decodedId.slice(1, -1);;
    } else if (strategy === 2) {
      youtubeId = decodedId.slice(0, 1) + decodedId.slice(4);
    } else if (strategy === 3) {
      youtubeId = decodedId.slice(0, 3) + decodedId.slice(5);
    }
  
    return youtubeId;
  }

  useEffect(() => {
    const fetchMovieDetail = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/movie/${tmdbId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch movie details');
        }
        const data = await response.json();
        data.id = deobfuscateYoutubeId(data.id, getStrategy(data));
        if (data.poster_path) {
          if (!data.poster_path.startsWith('https')) {
            data.poster_path = `https://image.tmdb.org/t/p/w500${data.poster_path}`;
          }
        } else {
          data.poster_path = `https://via.placeholder.com/300x450?text=No+Poster`;
        }
        setMovie(data);
        posthog.capture('movie_detail', {"movie": data.name});
      } catch (error) {
        console.error('Error fetching movie details:', error);
        setPlayerError('Failed to load movie details');
      }
    };

    fetchMovieDetail();
  }, [tmdbId]);

  useEffect(() => {
    if (movie) {
      // Clean up previous player instance
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      let watchDuration = 0;  // Track total watch duration
      let lastCheckedTime = null;  // Track last checked time for duration calculation

      const initializePlayer = () => {
        playerRef.current = new window.YT.Player(`youtube-player`, {
          height: '360',
          width: '640',
          videoId: movie.id,
          playerVars: {
            controls: 1,
            modestbranding: 1,
            rel: 0,
            autoplay: 1,
            start: getMovieTime(movie.tmdb_id) || localStorage.getItem('startMovieAt0') === "true" ? 0 : 1200,
            cc_load_policy: 0,
            origin: "https://cinemaneram.com/",
            showinfo: 0,
            mute: isIOS,
            playsinline: 1,
            enablejsapi: 1,
            // privacyEnhanced: true,
          },
          events: {
            onError: (event) => {
              console.error("Player error:", event.data);
              // commented out because it was causing the player to error out if movie before it was unavailable
              // setPlayerError(`Player error: ${event.data}`);
            },
            onStateChange: (event) => {
              if (event.data !== window.YT.PlayerState.PLAYING) {
                // Save last checked time when video pauses/ends
                lastCheckedTime = null;
                clearInterval(timeCheckIntervalRef.current);
                return;
              }

              // Start checking time when video plays
              if (event.data === window.YT.PlayerState.PLAYING) {
                timeCheckIntervalRef.current = setInterval(() => {
                  const currentTime = Math.floor(playerRef.current.getCurrentTime());
                  // Calculate watch duration
                  if (lastCheckedTime !== null) {
                    // Only add to duration if less than 40 seconds have passed
                    // This helps handle seeking events
                    const timeDiff = currentTime - lastCheckedTime;
                    if (timeDiff > 0 && timeDiff < 40) {
                      watchDuration += timeDiff;
                    }
                  }
                  lastCheckedTime = currentTime;
                  posthog.capture('watch_time', {"movie": movie.name, "time": watchDuration});
                  console.log(movie.name, `watchDuration: ${watchDuration}`);
                  
                  // Save to localStorage only if watch duration exceeds 100 seconds. 
                  // First 30 seconds does not get counted to watchDuration 
                  if (watchDuration >= 70) {
                    saveMovieTime(movie.tmdb_id, currentTime);
                  }
                }, 30000);
              }
            }
          },
        });
      };

      if (window.YT && window.YT.Player) {
        initializePlayer();
      } else {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = initializePlayer;
      }

      const watchLaterMovies = JSON.parse(localStorage.getItem('watchLaterMovies') || '[]');
      setIsWatchLater(watchLaterMovies.includes(movie.tmdb_id));
    }

    return () => {
      clearInterval(timeCheckIntervalRef.current);
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [movie]);

  // const handleUnmute = () => {
  //   if (playerRef.current && playerRef.current.unMute) {
  //     playerRef.current.unMute();
  //     setIsMuted(false);
  //   }
  // };

  const handleWatchLater = (movie) => {
    posthog.capture('add_to_watch_later', {"movie": movie.name});
    const movieIds = JSON.parse(localStorage.getItem('watchLaterMovies') || '[]');
    const index = movieIds.indexOf(movie.tmdb_id);
    if (index === -1) {
      movieIds.push(movie.tmdb_id);
      setIsWatchLater(true);
      showToast('Added to Watch Later');
    } else {
      movieIds.splice(index, 1);
      setIsWatchLater(false);
      showToast('Removed from Watch Later');
    }
    
    localStorage.setItem('watchLaterMovies', JSON.stringify(movieIds));
  };

  const toggleFullScreen = () => {
    const iframe = playerRef.current.getIframe();
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
      iframe.removeAttribute('aria-hidden');
      iframe.setAttribute('tabindex', '0');
      iframe.focus();
    }
  }

  const handleBack = () => {
    posthog.capture('click_back')
    navigate('/');
  };

  const showToast = (message, direction = 'top') => {
    const toast = document.createElement('div');
    toast.textContent = message;
    toast.style.position = 'fixed';
    toast.style[direction] = '0';
    toast.style.left = '50%';
    toast.style.transform = 'translateX(-50%)';
    toast.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
    toast.style.color = 'white';
    toast.style.padding = '10px';
    toast.style.borderRadius = '5px';
    document.body.appendChild(toast);
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 2000);
  };


  const handleNext = () => {
    posthog.capture('click_next_movie');
    if (currentIndex < movies.length - 1 && movies[currentIndex + 1]?.tmdb_id) {
      const nextMovie = movies[currentIndex + 1];
      // if (isIOS) {
      //   setIsMuted(true);
      // }
      navigate(`/movie/${nextMovie.tmdb_id}`, {
        state: { movies, currentIndex: currentIndex + 1 }
      });
    }
  };

  const handlePrevious = () => {
    posthog.capture('click_previous_movie');
    if (currentIndex > 0 && movies[currentIndex - 1]?.tmdb_id) {
      const prevMovie = movies[currentIndex - 1];
      // if (isIOS) {
      //   setIsMuted(true);
      // }
      navigate(`/movie/${prevMovie.tmdb_id}`, {
        state: { movies, currentIndex: currentIndex - 1 }
      });
    }
  };

  const handleSeek = (seconds) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + seconds, true);
    }
  };

  if (!movie) {
    return <div>Loading...</div>;
  }

  const handleActorClick = (actorName) => {
    posthog.capture('click_actor', {"actor": actorName});
    sessionStorage.removeItem('scrollPosition');
    navigate(`/?actors=${actorName}`);
  };

  const handleDirectorClick = (name) => {
    posthog.capture('click_director', {"director": name});
    sessionStorage.removeItem('scrollPosition');
    navigate(`/?director=${name}`);
  };

  const handleWriterClick = (name) => {
    posthog.capture('click_writer', {"writer": name});
    sessionStorage.removeItem('scrollPosition');
    navigate(`/?writer=${name}`);
  };


  // cleaning stuff starts


  const handleCleaningTextChange1 = (e) => {
    setCleaningText1(e.target.value);
  };

  const handleCleaningSubmit1 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_tmdb_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: movie.id,
          tmdb_id: cleaningText1
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 1');
      }

      setCleaningResponse1(JSON.stringify(result, null, 2));
      setCleaningText1(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 1:', error);
      setCleaningResponse1(`Error: ${error.message}`);
    }
  };

  const handleCleaningTextChange2 = (e) => {
    setCleaningText2(e.target.value);
  };

  const handleCleaningSubmit2 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_yt_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tmdb_id: movie.tmdb_id,
          yt_id: cleaningText2
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 2');
      }

      setCleaningResponse2(JSON.stringify(result, null, 2));
      setCleaningText2(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 2:', error);
      setCleaningResponse2(`Error: ${error.message}`);
    }
  };

  const handleCleaningSubmit3 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_tmdb_and_yt_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tmdb_id: cleaningText1,
          yt_id: cleaningText2
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 2');
      }

      setCleaningResponse3(JSON.stringify(result, null, 2));
      setCleaningText1(''); // Clear the input field
      setCleaningText2(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 2:', error);
      setCleaningResponse3(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container movie-detail">
      <div className="page-header">
        <span onClick={handleBack} className="back-button" tabIndex="0" role="button">
          <IoArrowBackOutline />
        </span>
        <span className="bookmark-button" onClick={() => handleWatchLater(movie)} tabIndex="0" role="button">
          {isWatchLater ? <IoHeart /> : <IoHeartOutline />}
        </span>
      </div>

      <div className="video-container">
        {playerError ? (
          <div className="error-message">{playerError}</div>
        ) : (
          <>
            <div tabIndex="0" id={`youtube-player`}></div>
            {isSmartTV && (
              <>
                <button 
                  className="seek-button seek-backward" 
                  onClick={() => handleSeek(-10)}
                  aria-label="Seek 10 seconds backward"
                  style={{ display: 'flex' }}
                >
                  <IoPlayBack size={30} />
                </button>
                <button 
                  className="seek-button seek-forward" 
                  onClick={() => handleSeek(10)}
                  aria-label="Seek 10 seconds forward"
                  style={{ display: 'flex' }}
                >
                  <IoPlayForward size={30} />
                </button>
                <button 
                  onClick={toggleFullScreen} 
                  className="fullscreen-button"
                  style={{ display: 'flex' }}
                >
                  <IoExpand size={24} />
                </button>
              </>
            )}
          </>
        )}
      </div>
      <div className="navigation-buttons">
        <button 
          onClick={handlePrevious} 
          disabled={currentIndex <= 0 || !movies[currentIndex - 1]} 
          className="nav-button prev-button"
        >
          Previous
        </button>
        <h3 className="movie-title">{movie.name}</h3>
        <button 
          onClick={handleNext} 
          disabled={currentIndex >= movies.length - 1 || !movies[currentIndex + 1]} 
          className="nav-button next-button"
        >
          Next
        </button>
        
      </div>
      <div className="movie-info">
        <img 
          src={movie.poster_path} 
          alt={movie.name} 
          className="movie-poster"
        />
        <div className="movie-details">
        <ExpandableText 
          text={localStorage.getItem('showPlotMl') === "true" ? movie.plot_ml : movie.plot} 
          maxLength={window.innerWidth <= 768 ? 150 : 150000} 
          key={movie.tmdb_id}
        />
        <p style={{ paddingTop: '10px'}}><strong>Genres:</strong> {movie.genres || 'Not available'}</p>
        <p><strong>Year:</strong> {movie.year || 'Not available'}</p>
        </div>
      </div>

      <div className="cast-crew-section">
        <h3>Cast</h3>
        <div className="cast-scroll">
          {movie.cast && movie.cast
            .sort((a, b) => a.order_number - b.order_number)
            .map((actor, index) => (
              <div 
                key={index} 
                className="cast-card" 
                onClick={() => handleActorClick(actor.name)}
                // onKeyPress={handleKeyPress}
                tabIndex="0"
                role="button"          
                >
                <div className="actor-image">
                  <img 
                    src={actor.photo_path 
                      ? `https://image.tmdb.org/t/p/w276_and_h350_face${actor.photo_path}`
                      : `https://www.gravatar.com/avatar/?d=mp&s=276`
                    } 
                    alt={actor.name} 
                  />
                </div>
                <div className="actor-info">
                  <div className="actor-name">{actor.name}</div>
                  {actor.character && (
                    <div className="actor-character">{actor.character}</div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <h3>Crew</h3>
        <div className="cast-scroll">
          {movie.crew && movie.crew.map((member, index) => (
            <div 
              key={index} 
              className="cast-card" 
              onClick={() => {
                if (member.job.toLowerCase() === 'director') {
                  handleDirectorClick(member.name);
                } else if (member.job.toLowerCase() === 'writer') {
                  handleWriterClick(member.name);
                } else {
                  showToast('No profile exists', 'bottom');
                }
              }}
              tabIndex="0"
              role="button" 
            >
              <div className="actor-image">
                <img 
                  src={member.photo_path 
                    ? `https://image.tmdb.org/t/p/w276_and_h350_face${member.photo_path}`
                    : `https://www.gravatar.com/avatar/?d=mp&s=276`
                  } 
                  alt={member.name} 
                />
              </div>
              <div className="actor-info">
                <div className="actor-name">{member.name}</div>
                <div className="actor-character">{member.job === 'Director of Photography' ? 'Cinematographer' : member.job}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {process.env.REACT_APP_ENV === "local" && (  
        <div>       
          <div className="data-cleaning-section">
            <input 
              type="text" 
              value={cleaningText1} 
              onChange={handleCleaningTextChange1} 
              placeholder="Enter tmdb id"
            />
            <button onClick={handleCleaningSubmit1}>Submit</button>
            {cleaningResponse1 && (
              <pre className="cleaning-response">{cleaningResponse1}</pre>
            )}
          </div>
          <div className="data-cleaning-section">
            <input 
              type="text" 
              value={cleaningText2} 
              onChange={handleCleaningTextChange2} 
              placeholder="Enter yt id"
            />
            <button onClick={handleCleaningSubmit2}>Submit</button>
            {cleaningResponse2 && (
              <pre className="cleaning-response">{cleaningResponse2}</pre>
            )}
          </div>
          <div className="data-cleaning-section">
            <button onClick={handleCleaningSubmit3}>Submit new</button>
            {cleaningResponse3 && (
              <pre className="cleaning-response">{cleaningResponse3}</pre>
            )}
          </div>
        </div>
        
      )}

    </div>
    
  );
};

export default MovieDetail;