import { useEffect, useCallback } from 'react';

export const useDPadNavigation = ({ selector, onEnter, enabled = true }) => {
  const getFocusableElements = useCallback(() => {
    return Array.from(document.querySelectorAll(selector));
  }, [selector]);

  const focusRandomMovieButton = () => {
    const randomButton = document.querySelector('.random-movie-button');
    if (randomButton) {
      randomButton.focus();
      return true;
    }
    return false;
  };

  const getClosestElement = (elements, currentElement, direction) => {
    const currentRect = currentElement.getBoundingClientRect();
    let closest = null;
    let minDistance = Infinity;

    elements.forEach((element) => {
      if (element === currentElement) return;
      const rect = element.getBoundingClientRect();
      
      // Calculate center points
      const currentCenter = {
        x: currentRect.left + currentRect.width / 2,
        y: currentRect.top + currentRect.height / 2,
      };
      const elementCenter = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };

      // Check if element is in the correct direction
      const isValidDirection =
        (direction === 'up' && elementCenter.y < currentCenter.y) ||
        (direction === 'down' && elementCenter.y > currentCenter.y) ||
        (direction === 'left' && elementCenter.x < currentCenter.x) ||
        (direction === 'right' && elementCenter.x > currentCenter.x);

      if (!isValidDirection) return;

      // Calculate distance using Pythagorean theorem
      const distance = Math.sqrt(
        Math.pow(elementCenter.x - currentCenter.x, 2) +
        Math.pow(elementCenter.y - currentCenter.y, 2)
      );

      if (distance < minDistance) {
        minDistance = distance;
        closest = element;
      }
    });
    // console.log("closest", closest);
    return closest;
  };

  const handleKeyDown = useCallback(
    (event) => {
      const isYouTubeIframe = document.activeElement.tagName.toLowerCase() === 'iframe' &&
        document.activeElement.src?.includes('youtube');
      
      if (isYouTubeIframe && event.key === 'Backspace') {
        event.preventDefault();
        event.stopPropagation();
        document.activeElement.blur();
        // Try to focus random movie button first
        if (!focusRandomMovieButton()) {
          const focusableElements = getFocusableElements();
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
        return;
      }

      // console.log("document.activeElement", document.activeElement, event.key);
      const focusableElements = getFocusableElements();
      const currentElement = document.activeElement;
      
      if (currentElement.tagName.toLowerCase() === 'body') {
        // Try to focus random movie button first
        if (!focusRandomMovieButton()) {
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
        return;
      }

      // Only proceed if we have a valid focused element that's in our focusable elements list
      if (!currentElement || !focusableElements.includes(currentElement)) {
        return;
      }

      let nextElement = null;

      // Special handling for seek buttons
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        const isSeekForward = currentElement.classList.contains('seek-forward');
        const isSeekBackward = currentElement.classList.contains('seek-backward');
        
        if (isSeekForward && event.key === 'ArrowLeft') {
          // If on seek-forward and pressing left, go to seek-backward
          nextElement = document.querySelector('.seek-backward');
          if (nextElement) {
            event.preventDefault();
            nextElement.focus();
            return;
          }
        } else if (isSeekBackward && event.key === 'ArrowRight') {
          // If on seek-backward and pressing right, go to seek-forward
          nextElement = document.querySelector('.seek-forward');
          if (nextElement) {
            event.preventDefault();
            nextElement.focus();
            return;
          }
        }
      }

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          nextElement = getClosestElement(focusableElements, currentElement, 'up');
          break;
        case 'ArrowDown':
          event.preventDefault();
          nextElement = getClosestElement(focusableElements, currentElement, 'down');
          break;
        case 'ArrowLeft':
          event.preventDefault();
          nextElement = getClosestElement(focusableElements, currentElement, 'left');
          break;
        case 'ArrowRight':
          event.preventDefault();
          nextElement = getClosestElement(focusableElements, currentElement, 'right');
          break;
        case 'Enter':
          event.preventDefault();
          if (onEnter && currentElement) {
            onEnter(currentElement);
          }
          break;
        case 'Backspace':
          event.preventDefault();
          if (currentElement) {
            currentElement.blur();
          }
          break;
      }

      if (nextElement) {
        nextElement.focus();
      }
    },
    [getFocusableElements, onEnter]
  );

  useEffect(() => {
    if (!enabled) return; // Skip setup if not enabled

    window.addEventListener('keydown', handleKeyDown, true);
    // Focus random movie button on mount
    focusRandomMovieButton();
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown, enabled]); // Add enabled to dependencies
}; 