// src/MovieListingPage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Filters from './Filters';
import MovieGrid from './MovieGrid';
import './MovieListingPage.css';
import { API_BASE_URL } from './constants';
import { useFilters } from './FilterContext';
import './layout.css'; // Import the new CSS file
import posthog from 'posthog-js';
import { useInView } from 'react-intersection-observer';
import { getWatchedMovies, getWatchedMovieDetails } from './utils/watchHistory';
import MovieCard from './MovieCard';
import { IoCloseOutline } from "react-icons/io5";


const loadingMessages = [
  "Loading..."
];

const MovieListingPage = ({ cachedMovies, setCachedMovies, lastFilters, setLastFilters }) => {
  const [movies, setMovies] = useState(cachedMovies);
  const [loading, setLoading] = useState(cachedMovies.length === 0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { filters, setFilters } = useFilters();
  const listRef = useRef(null);
  const location = useLocation();

  const [visibleMovies, setVisibleMovies] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);

  const [continueWatchingMovies, setContinueWatchingMovies] = useState([]);

  const [showContinueWatching, setShowContinueWatching] = useState(() => {
    // Initialize from sessionStorage, default to true if not set
    return !(sessionStorage.getItem('showContinueWatching') === 'false');
  });

  useEffect(() => {
    if (inView && hasMore) {
      loadMoreMovies();
    }
  }, [inView, hasMore]);

  const loadMoreMovies = () => {
    const nextMovies = movies.slice(visibleMovies.length, visibleMovies.length + 20);
    setVisibleMovies(prevMovies => [...prevMovies, ...nextMovies]);
    setHasMore(visibleMovies.length + nextMovies.length < movies.length);
  };

  useEffect(() => {
    setVisibleMovies(movies.slice(0, 20));
    setHasMore(movies.length > 20);
  }, [movies]);

  const setFiltersFromQueryParams = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const actor = queryParams.get('actors');
    const directors = queryParams.get('director');
    const writers = queryParams.get('writer');
    const year = queryParams.get('year');
    const genre = queryParams.get('genre');
    if (!actor && !directors && !writers && !year && !genre) {
      return;
    }
    // Set filters based on query parameters
    if (actor) {
      const actorsArray = actor.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, actors: actorsArray }));
    } else {
      setFilters(prev => ({ ...prev, actors: [] }));
    }
    if (directors) {
      const arr = directors.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, director: arr }));
    } else {
      setFilters(prev => ({ ...prev, director: [] }));
    }
    if (writers) {
      const arr = writers.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, writer: arr }));
    } else {
      setFilters(prev => ({ ...prev, writer: [] }));
    }
    if (year) {
      setFilters(prev => ({ ...prev, year: [year] }));
    } else {
      setFilters(prev => ({ ...prev, year: [] }));
    }
    if (genre) {
      setFilters(prev => ({ ...prev, genre: [genre] }));
    } else {
      setFilters(prev => ({ ...prev, genre: [] }));
    }
  }, [location.search, setFilters]); // Add dependencies
  
  useEffect(() => {
    setFiltersFromQueryParams(); // Set filters when the component mounts
  }, [setFiltersFromQueryParams]);

  const fetchMovies = useCallback(async () => {
    if (JSON.stringify(filters) === JSON.stringify(lastFilters)) {
      if (cachedMovies.length > 0) {
        setMovies(cachedMovies);
        setLoading(false);
        return;
      } else {
        // If cached movies are empty, don't make an API call
        setMovies([]);
        setLoading(false);
        
        setError('No movies found for the selected filters.');
        return;
      }
    }

    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams();
      if (filters.actors && filters.actors.length > 0) {
        queryParams.append('actors', filters.actors.join(','));
      }
      if (filters.director && filters.director.length > 0) {
        queryParams.append('director', filters.director.join(','));
      }
      if (filters.writer && filters.writer.length > 0) {
        queryParams.append('writer', filters.writer.join(','));
      }
      if (filters.year && filters.year.length > 0) {
        if (filters.year[0] === "Before 1980") {
          queryParams.append('end_year', 1980);
        }
        if (filters.year[0] === "1980-1990") {
          queryParams.append('start_year', 1980);
          queryParams.append('end_year', 1990);
        } 
        if (filters.year[0] === "1991-2000") {
          queryParams.append('start_year', 1991);
          queryParams.append('end_year', 2000);
        } 
        if (filters.year[0] === "2001-2010") {
          queryParams.append('start_year', 2001);
          queryParams.append('end_year', 2010);
        } 
        if (filters.year[0] === "After 2010") {
          queryParams.append('start_year', 2011);
        }
      }
      if (filters.genre && filters.genre.length > 0) {
        queryParams.append('genre', filters.genre.join(','));
      }
      if (localStorage.getItem('showDubbed') === "true") {
        queryParams.append('include_dubbed', 1);
      }
      
      const queryString = queryParams.toString();
      const url = `${API_BASE_URL}/movies${queryString ? `?${queryString}` : ''}`;
      
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data && Array.isArray(data.movies)) {
        setMovies(data.movies);
        setCachedMovies(data.movies);
        setLastFilters(filters);
      } else {
        throw new Error('Invalid data structure received from API');
      }
    } catch (error) {
      setError('Failed to fetch movies. Please try again later.');
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, setCachedMovies, setLastFilters, lastFilters, cachedMovies]);

  useEffect(() => {
    fetchMovies();
  }, [fetchMovies]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      // Calculate how many movies we need to load to reach the saved scroll position
      // Estimate based on average movie card height and spacing
      const estimatedItemsPerScreen = 20; // Adjust based on your layout
      const scrollPosition = parseInt(savedScrollPosition);
      const screenHeight = window.innerHeight;
      const estimatedScreensToScroll = Math.ceil(scrollPosition / screenHeight);
      const estimatedItemsNeeded = estimatedScreensToScroll * estimatedItemsPerScreen;
      
      // Load enough movies to reach the scroll position
      const itemsToLoad = Math.min(movies.length, Math.max(estimatedItemsNeeded, 20));

      setVisibleMovies(movies.slice(0, itemsToLoad));
      setHasMore(movies.length > itemsToLoad);
      
      // Restore scroll position after a short delay to allow rendering
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition));
        // Clear the saved position after restoring
        sessionStorage.removeItem('scrollPosition');
      }, 300);
    }
  }, [movies]);

  // setLoadingMessage
  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setCurrentLoadingMessage(prevMessage => {
          const currentIndex = loadingMessages.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % loadingMessages.length;
          return loadingMessages[nextIndex];
        });
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const handleMovieSelect = (movie, index) => {
    if (index === null) {
      posthog.capture('clicked_continue_watching_movie', {"movie": movie.name});
      navigate(`/movie/${movie.tmdb_id}`);
      return
    }
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('scrollPosition', scrollPosition.toString());
    navigate(`/movie/${movie.tmdb_id}`, {
      state: { 
        movies: movies.map(m => ({ tmdb_id: m.tmdb_id })),
        currentIndex: index
      }
    });
  };

  const handlePlayRandomMovie = () => {
    if (movies.length > 0) {
      posthog.capture('click_play_random_movie')
      const randomIndex = Math.floor(Math.random() * movies.length);
      const randomMovie = movies[randomIndex];
      handleMovieSelect(randomMovie, randomIndex);
    }
  };

  const watchedMovies = getWatchedMovies();
  const hasWatchHistory = Object.keys(watchedMovies).length > 0;

  const clearFilters = () => {
    setFilters({ actors: [], director: [], writer: [], year: [], genre: [] });
    navigate('', { replace: true });
  };

  useEffect(() => {
    const fetchWatchedMovies = async () => {
      const movieDetails = await getWatchedMovieDetails();
      
      const watchedList = Object.entries(watchedMovies)
        .map(([id, watchDetails]) => ({
          ...movieDetails[id],
          tmdb_id: id,
          watchDetails: watchDetails
        }))
        // .filter(movie => movie.title) // Filter out any movies that failed to fetch
        .sort((a, b) => (b.watchDetails.ts || 0) - (a.watchDetails.ts || 0))
        .slice(0, 10);
      setContinueWatchingMovies(watchedList);
    };
    if (showContinueWatching && hasWatchHistory) {
      fetchWatchedMovies();
    }

  }, []);

  const handleHideContinueWatching = () => {
    posthog.capture('click_hide_continue_watching')
    setShowContinueWatching(false);
    sessionStorage.setItem('showContinueWatching', 'false');
  };

  const handleRemoveMovie = (movieId) => {
    posthog.capture('click_remove_continue_watching_movie')
    // Remove from localStorage
    const watchedMovies = getWatchedMovies();
    delete watchedMovies[movieId];
    localStorage.setItem('movieWatchHistory', JSON.stringify(watchedMovies));

    // Remove from state
    setContinueWatchingMovies(prev => 
      prev.filter(movie => movie.tmdb_id !== movieId)
    );
  };

  return (
    <div>
      {/* <header className="app-header">
        <h1></h1>
        <div className="header-actions">
          <SearchBar />
          <Link to="/later" className="watch-later-link" aria-label="Watch Later">
            <FaRegBookmark />
          </Link>
        </div>
      </header> */}
      <div className="container">
        <div ref={listRef}>
          {continueWatchingMovies.length > 0 && showContinueWatching && (
            <div className="continue-watching-section">
              <div className="continue-watching-header">
                <div className="header-title">
                  <h3>Continue Watching</h3>
                </div>
                <button 
                  className="close-button"
                  onClick={handleHideContinueWatching}
                  aria-label="Hide continue watching section"
                >
                  <IoCloseOutline size={24} />
                </button>
              </div>
              <div className="continue-watching-scroll">
                {continueWatchingMovies.map((movie, index) => (
                  <MovieCard
                    key={movie.tmdb_id}
                    movie={movie}
                    onClick={() => handleMovieSelect(movie, null)}
                    className="continue-watching-item"
                    onRemove={() => handleRemoveMovie(movie.tmdb_id)}
                  />
                ))}
              </div>
            </div>
          )}
          
          <Filters />
          
          <button onClick={handlePlayRandomMovie} className="random-movie-button">
            <span className="play-icon">▶</span> Play any movie
          </button>
          
          {loading ? (
            <div className="loading-container">
              <p>{currentLoadingMessage}</p>
            </div>
          ) : error ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <p>{error}</p>
              <button onClick={clearFilters} className="clear-filters-button">Clear Filters</button>
            </div>
          ) : (
            <>
              <MovieGrid movies={visibleMovies} onMovieSelect={handleMovieSelect} />
              {hasMore && <div ref={ref} style={{ height: '20px' }}></div>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovieListingPage;