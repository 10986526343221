// src/App.js

import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MovieListingPage from './MovieListingPage';
import BottomNav from './components/BottomNav';
import SidebarNav from './components/SidebarNav';
import MovieDetail from './MovieDetail';
import WatchLaterPage from './pages/WatchLaterPage';
import SearchPage from './pages/SearchPage';
import { FilterProvider } from './FilterContext';
import './layout.css';
import SettingsPage from './pages/SettingsPage';
import InstallPage from './pages/InstallPage';
import { useDPadNavigation } from './hooks/useDPadNavigation';

const isSmartTV = () => {
  // Check for common Smart TV user agents
  const userAgent = navigator.userAgent.toLowerCase();
  console.log(userAgent);
  return (
    userAgent.includes('smart-tv') ||
    userAgent.includes('smarttv') ||
    userAgent.includes('tizen') ||
    userAgent.includes('webos') ||
    userAgent.includes('roku') ||
    userAgent.includes('android tv') ||
    userAgent.includes('googletv') ||
    (userAgent.includes('android') && userAgent.includes('large')) ||
    (userAgent.includes('android') && userAgent.includes('tv'))
  );
};

const App = () => {
  const [cachedMovies, setCachedMovies] = useState([]);
  const [lastFilters, setLastFilters] = useState(null);
  
  // Calculate isSmartTV value once and memoize it
  const isTVDevice = useMemo(() => isSmartTV(), []);

  // Always call the hook, but only enable navigation for Smart TVs
  useDPadNavigation({
    selector: 'button:not([disabled]), a, [role="button"], .movie-card, .nav-item, input[type="text"], input[type="search"]',
    onEnter: (element) => {
      if (element.click) {
        element.click();
      }
    },
    enabled: isTVDevice // Use the memoized value
  });

  return (
    <Router>
      <FilterProvider>
        <div className="container">
          <SidebarNav isSmartTV={isTVDevice} />
          <Routes>
            <Route 
              path="/" 
              element={
                <MovieListingPage 
                  cachedMovies={cachedMovies} 
                  setCachedMovies={setCachedMovies}
                  lastFilters={lastFilters}
                  setLastFilters={setLastFilters}
                />
              } 
            />
            <Route path="/movie/:tmdbId" element={<MovieDetail isSmartTV={isTVDevice} />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/later" element={<WatchLaterPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/install" element={<InstallPage />} />
          </Routes>
          <BottomNav isSmartTV={isTVDevice} />
        </div>
      </FilterProvider>
    </Router>
  );
};

export default App;