export const API_BASE_URL = (process.env.REACT_APP_ENV === "local") ? "http://localhost:8000": "https://cinemaneram.com/api"


export const DIRECTORS = [
    "P. Padmarajan",
    "Joshiy",
    "Priyadarshan",
    "Sathyan Anthikad",
    "Sibi Malayil",
    "Kamal",
    "I V Sasi",
    "Bharathan",
    "Siddique",
    "Govindan Aravindan",
    "Adoor Gopalakrishnan",
    "K. G. George",
    "T Hariharan",
    "Shaji Kailas",
    "K. Madhu",
    "Vinayan",
    "Rajasenan",
    "Jayaraj",
    "Fazil",
    "Bhadran",
    "Thulasidas",
    "Anil Kumar",
    "Babu Narayanan",
    "Sajan",
    "M. Krishnan Nair",
    "Balachandra Menon",
    "Viji Thamby",
    "P Bhaskaran",
    "Mohan",
    "Balu Kiriyath",
    "T S Suresh Babu",
    "K K Haridas",
    "Venu Nagavalli",
    "Thampi Kannanthanam",
    "Jose Thomas",
    "A. Vincent",
    "V M Vinu",
    "Raffi",
    "Mecartin",
    "Lal Jose",
    "Baby",
    "A. B. Raj",
    "A K Lohithadas",
    "V. K. Prakash",
    "Thaha",
    "T. K. Rajeev Kumar",
    "Ranjith",
    "Lal",
    "Jeassy",
    "Cochin Haneefa",
    "Sunil",
    "Shyamaprasad",
    "Sandhya Mohan",
    "Rosshan Andrrews",
    "Padmakumar M",
    "P.K. Joseph",
    "K G Rajasekharan",
    "Jomon",
    "George Kithu",
    "Crossbelt Mani",
    "Akku Akbar",
    "T. V. Chandran",
    "T. S. Mohan",
    "Shafi",
    "Sasi Shankar",
    "Ranjith Sankar",
    "Ramu Kariat",
    "Pramod",
    "Pappan",
    "P G Viswambharan",
    "P. Venu",
    "P. Chandrakumar",
    "P. Anil",
    "P Subramaniam",
    "P N Menon",
    "Nissar",
    "N Sankaran Nair",
    "Mohan Kupleri",
    "Mani Ratnam",
    "M. Mani",
    "Lenin Rajendran",
    "Kaladharan Adoor",
    "Jeethu Joseph",
    "Harikumar",
    "Haridas",
    "G. S. Vijayan",
    "B. Unnikrishnan",
    "Vysakh",
    "Vijayanand",
    "Venugopan",
    "V. Ashok Kumar",
    "Thoppil Bhasi",
    "T S Saji",
    "Suresh Vinu",
    "Shajoon Kariyal",
    "J Sasikumar",
    "Sangeeth Sivan",
    "Sameer Thahir",
    "Ramesh Varma",
    "Prathap Pothan",
    "P K Baburaj",
    "Major Ravi",
    "Lijo Jose Pellissery",
    "Kunchacko",
    "K. B. Madhu",
    "K Sreekuttan",
    "Joshi Mathew",
    "K. S. Sethumadhavan",
    "John Abraham",
    "Jijo Antony",
    "J. Williams",
    "Dennis Joseph",
    "Boban Samuel",
    "Biju Varkey",
    "Ashok R Nath",
    "Anwar Rasheed",
    "Anjali Menon",
    "Amal Neerad",
    "Alleppey Ashraf",
    "Ali Akbar",
    "Yousufali Kecheri",
    "Vipin Das",
    "Vinod Mankara",
    "Vijayan K",
    "Venkat Prabhu",
    "Vasudev Sanal",
    "V.R. Gopalakrishnan",
    "Tinu Pappachan",
    "Suresh Unnithan",
    "Sudin Menon",
    "Sreenivasan",
    "Shaji Azeez",
    "Sekhar Kammula",
    "Saji Surendran",
    "S.L Puram Jayasurya",
    "S S Rajan",
    "S A Salam",
    "Renji Panicker",
    "Rajesh Pillai",
    "Rajesh Nair",
    "Rajeev Ravi",
    "Rajeev Nath",
    "Rajan Sankaradi",
    "R. Sukumaran",
    "R Sarath",
    "Puri Jagannadh",
    "Pappan Naripatta",
    "P. N. Sundaram",
    "P. A. Backer",
    "P K Radhakrishnan",
    "N. P. Suresh",
    "Martin Prakkat",
    "Mahesh",
    "Madhupal",
    "M. Mohanan",
    "M T Vasudevan Nair",
    "Kottayam Chellappan",
    "Khalid Rahman",
    "K. Biju",
    "Jeo Baby",
    "Haridas Kesavan",
    "Gokul Ramakrishnan",
    "G. Premkumar",
    "G. Marthandan",
    "Blessy",
    "Bijukumar Damodaran",
    "Arun Vaiga",
    "Arun Kumar Aravind",
    "Anvar Sadik",
    "Antony Eastman",
    "Aby Jose",
    "Aashiq Abu",
    "A. L. Vijay",
    "Sheela",
    "Jibu Jacob",
    "Chandrasekharan",
    "Baiju Kottarakkara",
    "A. Sheriff",
    "Venu",
    "Ratheesh Balakrishnan Poduval",
    "Jijo Punnoose",
    "Rajesh Narayanan",
    "Pradeep Chokli",
    "Anish Varma",
    "Madhu",
    "T. Hariharan",
    "Nisar",
    "M M Nesan",
    "Sreekumaran Thampi",
    "Sundar Das",
    "Jis Joy",
    "Salim Baba",
    "V. K. Pavithran",
    "Vijayanarayanan",
    "Hassan",
    "Paul Babu",
    "Anil",
    "P T Rajan",
    "Soman Ambaat",
    "Midhun Manuel Thomas",
    "Vijayan Karote",
    "Santosh Sivan",
    "Radhakrishnan",
    "Ashokan",
    "Sathish Mannarkad",
    "Shibu Gangadharan",
    "Pradeep Kumar",
    "Ajai Vasudev",
    "M A Venu",
    "Johny Antony",
    "Boban Kunchacko",
    "Ambili"

]

export const ACTORS = [
    "Mammootty",
    "Mohanlal",
    "Prem Nazir",
    "Urvashi",
    "Shobana",
    "Suresh Gopi",
    "Jayan",
    "Jagathy Sreekumar",
    "Mukesh",
    "Nedumudi Venu",
    "Adoor Bhasi",
    "Jayaram",
    "Madhu",
    "Jagadish",
    "Dileep",
    "Sreenivasan",
    "Kunchacko Boban",
    "Siddique",
    "Prithviraj Sukumaran",
    "Kalabhavan Mani",
    "Sathyan",
    "Innocent",
    "Thilakan",
    "Seema",
    "Sukumaran",
    "Jayan",
    "Murali",
    "Jayabharathi",

    "Shankar",
    "Sheela",
    "Biju Menon",
    "Sharada",
    "Indrajith Sukumaran",
    "Ratheesh",
    "Rahman",
    "Jayasurya",
    "Srividya",
    "Manoj K Jayan",
    "Geetha",
    "Balachandra Menon",
    "Vijayaraghavan",
    "Kavya Madhavan",
    "Meena",
    "Manju Warrier",
    "Vani Viswanath",
    "Sumalatha",
    "Suhasini Maniratnam",
    "Menaka Suresh",
    "Kamal Haasan",
    "Dulquer Salmaan",
    "Divyaa Unni",
    "Prem Kumar",
    "Parvathi",
    "Mamta Mohandas",
    "Lissy",
    "Gopi",
    "Fahadh Faasil",
    "Babu Antony",
    "Ashokan",
    "Vineeth Radhakrishnan",
    "Shalini",
    "Saikumar",
    "M G Soman",
    "Lal",
    "Karthika",
    "Thikkurissy Sukumaran Nair",
    "Sridevi",
    "Revathi",
    "Nivin Pauly",
    "Navya Nair",
    "Maniyanpilla Raju",
    "Madhavi",
    "Lakshmi",
    "Annie",
    "Ambika",
    "Zarina Wahab",
    "Vijay",
    "Sunitha",
    "Shanthi Krishna",
    "Samyuktha Varma",
    "Rekha",
    "Ranjini",
    "Nayanthara",
    "Mohini",
    "Meera Jasmine",
    "Kaviyoor Ponnamma",
    "Indrans",
    "Asif Ali",
    "Anoop Menon",
    "Harisree Ashokan",
    "Nellikode Bhaskaran",
    "Jagannatha Varma",
    "Vijayakumar",
    "Bobby Kottarakkara",
    "Khushboo",
    "Balu Varghese",
    "Mahesh",
    "Sukumari",
    "Idavela Babu",
    "Ranjitha",
    "Murali Gopy",
    "Kaniha",
    "George",
    "Vincent",
    "Unni Mukundan",
    "Raghuvaran",
    "Adoor Bhavani",
    "Bijukuttan",
    "Sudheesh",
    "Sathaar",
    "Meera Nandan",
    "Sithara",
    "Bhanupriya",
    "Nishan K. P. Nanaiah",
    "Vijayasree",
    "Vineeth Kumar",
    "Chithra",
    "Samyuktha",
    "Vinu Mohan",
    "Prakash Raj",
    "Shanawas",
    "Balan K Nair",
    "Lakshmi Gopalaswamy",
    "T G Ravi",
    "Aishwarya Bhaskar",
    "Joy Mathew",
    "Kalpana",
    "Shweta Menon",
    "Sangita",
    "Suparna Anand",
    "Krishnachandran",
    "Prathapa Chandran",
    "Nikhila Vimal",
    "K R Vijaya",
    "Jose",
    "Vikram",
    "Kaveri",
    "Basil Joseph",
    "Oduvil Unnikrishnan",
    "Nassar",
    "Dhanya Mary Varghese",
    "Rizabawa",
    "Shivaji Guruvayoor",
    "Thyagarajan",
    "Mohan Sharma",
    "Indraja",
    "Cochin Haneefa",
    "Maqbool Salmaan",
    "Kottayam Nazeer",
    "Achankunju",
    "Sathyakala",
    "Dileesh Pothan",
    "Sankaradi",
    "Ananya",
    "Radha",
    "Miya George",
    "Aju Varghese",
    "Soubin Shahir",
    "Rony David Raj",
    "Roshan Mathew",
    "Paravoor Bharathan",
    "T. S. Muthaiah",
    "Zainuddin",
    "Raveendran",
    "M S Thrippunithura",
    "Geethu Mohandas",
    "Siju Wilson",
    "Ranjith",
    "Swapna",
    "Poornima Indrajith",
    "Beena Antony",
    "Poojappura Ravi",
    "Sreenath Bhasi",
    "Vinaya Prasad",
    "Mamukkoya",
    "Anu Sithara",
    "Nanditha Bose",
    "Madhu Warrier",
    "Shubha",
    "Prathap Pothan",
    "Suchitra",
    "Kalabhavan Navas",
    "Babu Namboothiri",
    "Jishnu Raghavan",
    "Nithya Das",
    "N Govindankutty",
    "Baburaj",
    "Kanaka",
    "C I Paul",
    "Saiju Kurup",
    "T R  Omana",
    "Poornima Bhagyaraj",
    "Riyaz Khan",
    "Roja Ramani",
    "S. P. Pillai",
    "Renji Panicker",
    "Muktha George",
    "Sudheer Karamana",
    "Saritha",
    "Sujatha",
    "Ramya Krishnan",
    "Azeez",
    "Kalabhavan Shajon",
    "Jayabharati",
    "Namitha Pramod",
    "Manya",
    "Charu Haasan",
    "Manikuttan",
    "Shamili",
    "Aparna Nair",
    "Antony Varghese",
    "Karamana Janardanan Nair",
    "Ambika Mohan",
    "Jalaja",
    "Charmila",
    "Valsala Menon",
    "KPAC Lalitha",
    "Bala",
    "Ganesh Kumar",
    "Monisha Unni",
    "Suraj Venjaramoodu",
    "Karthika Mathew",
    "Unni Mary",
    "Saranya Ponvannan",
    "Sunny Wayne",
    "Baiju Santhosh",
    "Kanakalatha",
    "Kausalya",
    "Bahadoor",
    "Cochin Hanifa",
    "Sharafudheen",
    "Sarath Kumar",
    "Mala Aravindan",
    "Captain Raju",
    "Krishna",
    "Rani Padmini",
    "Vimala Raman",
    "Jacob Gregory",
    "Vijay Menon",
    "Rambha",
    "Jomol",
    "Tini Tom",
    "Deepak Parambol",
    "Parvathy Thiruvothu",
    "Madhoo",
    "Kailash",
    "P J Antony",
    "Kalasala Babu",
    "Bhavana",
    "Irshad",
    "Ambika Sukumaran",
    "Ilavarasi",
    "Anoop Chandran",
    "Anju Prabhakar",
    "Sreenath",
    "Shine Tom Chacko",
    "Praveena",
    "Vinduja Menon",
    "Anuradha",
    "Bhama",
    "Shiju",
    "Sreelakshmi",
    "Maathu",
    "Vinayakan",
    "Prabhu",
    "Santhakumari",
    "Kottarakkara Sreedharan Nair",
    "Gopika",
    "Padmini",
    "Sukanya",
    "Priya Raman",
    "Gemini Ganesan",
    "Sreejith Ravi",
    "Mithra Kurian",
    "Kuthiravattam Pappu",
    "Prema Menon",
    "Rani Chandra",
    "Gautami Tadimalla",
    "Vijay Babu",
    "Remya Nambeesan",
    "Shari",
    "Chemban Vinod Jose",
    "K P Ummer",
    "Kunchan",
    "Ragini",
    "Nandhu",
    "Saleema",
    "Bhagath Manuel",
    "Adoor Pankajam",
    "Rajan P Dev",
    "Sreelatha",
    "Narendra Prasad",
    "Sidhartha Siva",
    "Hareesh Perumanna",
    "Venu Nagavalli",
    "Bharathi Rao",
    "Kottayam Chellappan",
    "Nalini",
    "Aranmula Ponnamma",
    "Manavalan Joseph",
    "M R Gopakumar",
    "Narain",
    "Samvrutha Sunil",
    "Bheeman Raghu",
    "Sanusha Santhosh",
    "Meghana Raj",
    "Dominic Alummoodan",
    "Sidharth Bharathan",
    "Meera Vasudevan",
    "Shammi Thilakan",
    "Karan",
    "Philomina",
    "N F Varghese",
    "Aruna Mucherla",
    "Madhupal",
    "Urmila Unni",
    "Devayani",
    "Anju Aravind",
    "Rohini",
    "Kollam Thulasi",
    "Nadhiya",
    "Sona Nair",
    "Roma Asrani",
    "Raai Laxmi",
    "Jose Prakash",
    "Lenaa",
    "Jaffer Idukki",
    "Nishanth Sagar",
    "Sneha",
    "Shoba",
    "Kalaranjini",
    "Jyothirmayi",
    "Bindu Panicker",
    "Tovino Thomas",
    "Geetha Vijayan",
    "V K Sreeraman",
    "Vidhubala",
    "Nilambur Balan",
    "Sunil Sukhada",
    "Devan",
    "Joju George",
    "P.J. Anthony",
    "Dharmajan Bolgatty",
    "K.P.A.C. Sunny",
    "Salim Kumar",
    "Anumol",
    "Nadirshah",
    "Lalu Alex",
    "Augustine",
    "Anusha",
    "Sarayu Mohan",
    "Radhika",
    "Chippy",
    "Prakash Bare",
    "Santha Devi",
    "Vinay Forrt",
    "Janardhanan",
    "Hemanth Menon",
    "Padmapriya Janakiraman",
    "Suresh Krishna"
]

export const WRITERS = [
    "Sreenivasan",
    "A K Lohithadas",
    "Thoppil Bhasi",
    "M T Vasudevan Nair",
    "Dennis Joseph",
    "Benny P. Nayarambalam",
    "Kaloor Dennis",
    "Renji Panicker",
    "P. Padmarajan",
    "Bobby",
    "Sanjay",
    "Ranjith",
    "S. N. Swamy",
    "T Damodaran",   
    "John Paul",
    "Priyadarshan",
    "Uday Krishna",
    "Siby K Thomas",
    "Raffi",
    "Mecartin",
    "Sasidharan Arattuvazhi",
    "Fazil",
    "Siddique",
    "Raghunath Paleri",
    "Joy Pallassery",
    "Balachandra Menon",
    "Vinu Kiriyath",
    "Rajan Kiriyath",
    "V. C. Ashok",
    "Pappanamkodu Lakshmanan",
    "Babu Janardhanan",
    "A K Sajan",
    "T. A. Razzaq",
    "Cochin Haneefa",
    "Vinayan",
    "Robin Thirumala",
    "Lal",
    "Venu Nagavalli",
    "Kalavoor Ravikumar",
    "A. Sheriff",
    "B. Jayachandran",
    "Mohan",
    "Mani Shoranur",
    "K T Muhammed",
    "Sreekumaran Thampi",
    "Parappurathu",
    "V. R. Gopalakrishnan",
    "Rajesh Jayaraman",
    "J. Williams",
    "Lenin Rajendran",
    "Sathyan Anthikad",
    "Velliman Vijayan",
    "Suresh Babu",
    "Dr. Balakrishnan",
    "Blessy",
    "Bharathan",
    "Mutathu Varkey",
    "T Hariharan",
    "Nagavally R S Kurup",
    "Shaji Pandavath",
    "P. Balakrishnan",
    "Jagathy N K Achari",
    "Kamal",
    "K.P. Kottarakara",
    "Balu Kiriyath",
    "Salim Cherthala",
    "P N Menon",
    "Vijayan Karote",
    "Baby",
    "K. Balachander",
    "Purushan Alappuzha",
    "Major Ravi",
    "Ranjith Sankar",
    "P K Sarangapani",
    "Govindan Aravindan",
    "N Govindankutty",
    "Sathrughnan",
    "P Balachandran",
    "Adoor Gopalakrishnan",
    "Gireesh Puthenchery",
    "K. G. George",
    "Jayaraj",
    "V T Nandakumar",
    "Shyamaprasad",
    "B. Unnikrishnan",
    "Jeethu Joseph",
    "Sunil",
    "Vaikom Muhammad Basheer",
    "Govardhan",
    "S L Puram Sadanandan",
    "Jagadish",
    "T. K. Rajeev Kumar",
    "Sethu",
    "Madhu Muttam",
    "Ansar Kalabhavan",
    "Ranjan Pramod",
    "J Sasikumar",
    "K.Gireesh Kumar",
    "Bhadran",
    "Sachy",
    "Rajasenan",
    "T. V. Chandran",
    "K P Kottarakkara",
    "Mahesh Mithra",
    "Pamman",
    "Syam Pushkaran",
    "Y. V. Rajesh",
    "Shankar Ramakrishnan",
    "James Albert",
    "Babu Pallassery",
    "Nedumudi Venu",
    "Thakazhi Sivasankara Pillai",
    "Hassan",
    "Shibu Chakravarthy",
    "Cheriyan Kalpakavadi",
    "T A Shahid",
    "Jeo Baby",
    "A K Santhosh",
    "Kanam E. J.",
    "Rafeeque Seelattu",
    "M Mukundan"
]

export const GENRES = {
    28: "Action",
    12: "Adventure",
    16: "Animation",
    35: "Comedy",
    80: "Crime",
    18: "Drama",
    10751: "Family",
    14: "Fantasy",
    36: "History",
    27: "Horror",
    10402: "Music",
    9648: "Mystery",
    10749: "Romance",
    878: "Science Fiction",
    53: "Thriller",
    10752: "War",
}

export const GENRE_IDS = [
    35, // Comedy
    18, // Drama
    28, // Action
    53, // Thriller
    10751, // Family
    10402, // Music
    9648, // Mystery
    27, // Horror
    80, // Crime
    10752, // War
    14, // Fantasy
    878, // Science Fiction
    10749, // Romance    
    12, // Adventure
    36, // History
    16, // Animation
]