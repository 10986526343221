import { API_BASE_URL } from '../constants';

const WATCH_HISTORY_KEY = 'movieWatchHistory';

export const saveMovieTime = (tmdbId, time) => {
  const history = JSON.parse(localStorage.getItem(WATCH_HISTORY_KEY) || '{}');
  history[tmdbId] = {
    t:time,
    ts: Math.floor(Date.now() / 1000),
  };
  localStorage.setItem(WATCH_HISTORY_KEY, JSON.stringify(history));
};

export const getWatchedMovies = () => {
  const watchHistory = localStorage.getItem(WATCH_HISTORY_KEY);
  return watchHistory ? JSON.parse(watchHistory) : {};
};

export const getWatchedMovieDetails = async () => {
  const watchHistory = getWatchedMovies();
  const movieIds = Object.keys(watchHistory);

  if (movieIds.length === 0) {
    return {};
  }

  try {
    const response = await fetch(`${API_BASE_URL}/movies?ids=${movieIds.join(',')}`);
    if (!response.ok) {
      throw new Error('Failed to fetch watched movies');
    }
    const data = await response.json();
    
    // Convert array to object with tmdb_id as keys
    return data.movies.reduce((acc, movie) => {
      acc[movie.tmdb_id] = movie;
      return acc;
    }, {});
  } catch (error) {
    console.error('Error fetching watched movies:', error);
    return {};
  }
};

export const getMovieTime = (tmdbId) => {
  const history = getWatchedMovies();
  return history[tmdbId]?.t || null;
};

export const clearWatchHistory = () => {
  localStorage.removeItem(WATCH_HISTORY_KEY);
}; 