import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { IoArrowBackOutline } from "react-icons/io5";
import { FaGooglePlay, FaShare } from "react-icons/fa";
import './SettingsPage.css';
import posthog from 'posthog-js';

const SettingsPage = () => {
  const navigate = useNavigate();
  const [showDubbed, setShowDubbed] = useState(() => {
    return localStorage.getItem('showDubbed') === 'true';
  });
  
  const [showPlotMl, setShowPlotMl] = useState(() => {
    return localStorage.getItem('showPlotMl') === 'true';
  });

  const [startMovieAt0, setStartMovieAt0] = useState(() => {
    return localStorage.getItem('startMovieAt0') === 'true';
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleDubbedToggle = (checked) => {
    posthog.capture('toggled_dubbed_movies', {"value": checked})
    setShowDubbed(checked);
    localStorage.setItem('showDubbed', checked);
  };

  const handlePlotToggle = (checked) => {
    posthog.capture('toggled_plot_ml', {"value": checked})
    setShowPlotMl(checked);
    localStorage.setItem('showPlotMl', checked);
  };

  const handleMovieStartToggle = (checked) => {
    posthog.capture('toggled_movie_start', {"value": checked})
    setStartMovieAt0(checked);
    localStorage.setItem('startMovieAt0', checked);
  };

  // Add this function to detect WebView
  const isAndroidWebView = () => {
    return navigator.userAgent.includes('wv'); // 'wv' indicates WebView
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Cinema Neram',
          text: 'Check out Cinema Neram - Discover and watch Malayalam movies for free',
          url: window.location.origin + "/install"
        });
      }
    } catch (error) {
      console.log('Error sharing:', error);
    }
  };

  return (
    <div className="settings-page">
      <div className="settings-header">
        <span onClick={handleBack} className="back-button">
          <IoArrowBackOutline />
        </span>
        <h1>Settings</h1>
      </div>
      
      <div className="settings-section">
        <div className="setting-item">
          <div className="setting-info">
            <h3>Start movie from beginning</h3>
          </div>
          <Switch
            onChange={handleMovieStartToggle}
            checked={startMovieAt0}
            onColor="#bb86fc"
            offColor="#666"
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            handleDiameter={20}
          />
        </div>

        <div className="setting-item">
          <div className="setting-info">
            <h3>Show dubbed movies</h3>
          </div>
          <Switch
            onChange={handleDubbedToggle}
            checked={showDubbed}
            onColor="#bb86fc"
            offColor="#666"
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            handleDiameter={20}
          />
        </div>

        <div className="setting-item">
          <div className="setting-info">
            <h3>Show movie plot in Malayalam</h3>
          </div>
          <Switch
            onChange={handlePlotToggle}
            checked={showPlotMl}
            onColor="#bb86fc"
            offColor="#666"
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            handleDiameter={20}
          />
        </div>
      </div>

      <div className="settings-footer">
        {!isAndroidWebView() && (
          <button 
            onClick={handleShare} 
            className="share-button"
          >
            <FaShare /> Share App
          </button>
        )}
        {isAndroidWebView() && (
          <a 
            href="https://play.google.com/store/apps/details?id=com.matdomput.cinemaneram" 
            target="_blank" 
            rel="noopener noreferrer"
            className="store-link2"
          >
            <FaGooglePlay /> Rate App
          </a>
        )}
        {!isAndroidWebView() && !window.matchMedia('(display-mode: standalone)').matches && (
          <a 
            href="/install" 
            className="install-button"
          >
            Install App
          </a>
          
        )}
        <a href="https://www.buymeacoffee.com/matdomput" target="_blank" rel="noreferrer">
        <img className="buy" src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee"></img>
        </a>
        <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSe5gG7dWCfQcYYsyZjjU1lNczqwTU7LxFScYyNgLoHcBbp8qQ/viewform?usp=header" 
            target="_blank" 
            rel="noopener noreferrer"
            className="privacy-link"
        >
            Feedback
        </a>
        <a 
            href="https://boggy-fur-7bc.notion.site/Privacy-Policy-14d79e49d6ee80fe9c68f53f79597737" 
            target="_blank" 
            rel="noopener noreferrer"
            className="privacy-link"
          >
            Privacy Policy
          </a>
      </div>
    </div>
  );
};

export default SettingsPage;