// src/MovieGrid.js

import React, { useState, useEffect } from 'react';
import MovieCard from './MovieCard';
import './MovieGrid.css';
import posthog from 'posthog-js';

const MovieGrid = ({ movies, onMovieSelect }) => {
  const [itemsPerButton, setItemsPerButton] = useState(25); // 5 rows × 5 items for web initially

  useEffect(() => {
    const updateItemsPerButton = () => {
      const isMobile = window.innerWidth <= 768;
      setItemsPerButton(isMobile ? 10 * 2 : 7 * 5); // 10 rows × 2 items for mobile, 7 rows × 5 items for web
    };

    updateItemsPerButton();
    window.addEventListener('resize', updateItemsPerButton);
    return () => window.removeEventListener('resize', updateItemsPerButton);
  }, []);

  if (!movies || movies.length === 0) {
    return <div>No movies available</div>;
  }

  const handlePlayRandom = () => {
    posthog.capture('click_play_random_movie_n')
    const randomIndex = Math.floor(Math.random() * movies.length);
    onMovieSelect(movies[randomIndex], randomIndex);
  };

  return (
    <div className="movie-grid">
      {movies.map((movie, index) => (
        <React.Fragment key={movie.id || index}>
          <MovieCard 
            movie={movie} 
            onClick={() => onMovieSelect(movie, index)} 
          />
          {(index + 1) % itemsPerButton === 0 && index !== movies.length - 1 && (
            <div className="random-movie-container">
              <button onClick={handlePlayRandom} className="random-movie-button">
                <span className="play-icon">▶</span> Play any movie
              </button>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MovieGrid;