import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline, IoShareOutline } from "react-icons/io5";
import { FaGooglePlay } from "react-icons/fa";
import './InstallPage.css';

const InstallPage = () => {
  const navigate = useNavigate();
  const [isIOS, setIsIOS] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    // Detect iOS: this covers iPad, iPhone, and iPod
    const isIOS =
        /iPad|iPhone|iPod/.test(ua) ||
        // iPadOS 13+ returns 'MacIntel' but has touch support
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsIOS(isIOS);

    // For iOS, we want to differentiate Safari from other browsers or webviews.
    // iOS Safari includes a "Version/" string in its user agent.
    // Many alternative browsers and in-app webviews on iOS do not include this substring.
    const isSafari = /Version\/[\d.]+.*Safari/.test(ua);
    return setIsSafari(isSafari)
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="install-page">
      <div className="install-header">
        <span onClick={handleBack} className="back-button">
          <IoArrowBackOutline />
        </span>
        <h1>Install</h1>
      </div>
      
      <div className="install-content">
        
        {isIOS && (
          <div className="install-section">
            <h3>Install on your iPhone:</h3>
            <ul>
              {!isSafari && (
                <>
                  <li>
                    <a href="x-safari-https://cinemaneram.com/install" className="safari-link">
                      Click here
                    </a>
                    &nbsp;
                    to open in Safari
                  </li>
                </>
              )}
              {isSafari && (
                <>
                    <li>Tap the <IoShareOutline /> button below</li>
                    <li>Scroll down and tap "Add to Home Screen"</li>
                    <li>Tap "Add" to confirm</li>
                    <button 
                      className="show-tutorial-btn"
                      onClick={() => setShowTutorial(!showTutorial)}
                    >
                      {showTutorial ? 'Hide Tutorial' : 'Show Tutorial'}
                    </button>
                </>
              )}
            </ul>
            {showTutorial && (
              <div className="install-gif">
                <img 
                  src="/images/output2.gif" 
                  alt="Installation guide"
                  className="install-guide-gif"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        )}

        {!isIOS && (
          <div className="install-section">
            <a 
              href="https://play.google.com/store/apps/details?id=com.matdomput.cinemaneram" 
              target="_blank" 
              rel="noopener noreferrer"
              className="store-link"
            >
              <FaGooglePlay /> Get Android App
            </a>
          </div>
        )}

        <div className="install-section website-section">
          <p>Or visit</p>
          <a 
            href="/" 
            className="website-link"
          >
            cinemaneram.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstallPage; 