// src/Filters.js

import React, { useState } from 'react';
import Select from 'react-select';
import { ACTORS, DIRECTORS, WRITERS, GENRES, GENRE_IDS } from './constants';
import './Filters.css';
import { useFilters } from './FilterContext';
import posthog from 'posthog-js';
import { IoFilterOutline, IoChevronDown, IoChevronUp } from 'react-icons/io5';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#121212', // Dark background for the input control
    color: '#fff',           // Text color
    borderColor: 'grey',
    fontSize: '16px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#333', // Dark background for the dropdown menu
    zIndex: 9999, // Increase z-index to ensure dropdown isn't cut off
    position: 'absolute',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999, // Ensure the portal has a high z-index as well
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#555' : '#333', // Highlight the option when focused
    color: '#fff',  // Text color
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#555',
  }),
};

const Filters = () => {
  const { filters, setFilters } = useFilters();
  const [isExpanded, setIsExpanded] = useState(false);
  
  const hasActiveFilters = 
    filters.actors.length > 0 || 
    filters.director.length > 0 || 
    filters.writer.length > 0 || 
    filters.genre.length > 0 ||
    filters.year.length > 0;

  const toggleFilters = () => {
    posthog.capture('toggle_filter_panel', { "action": isExpanded ? "collapse" : "expand" });
    setIsExpanded(!isExpanded);
  };

  const handleActorChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter_actor', {"values": values});
    }
    const newFilters = {
      ...filters,
      actors: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleDirectorChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter_director', {"values": values});
    }
    const newFilters = {
      ...filters,
      director: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleWriterChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter_writer', {"values": values});
    }
    const newFilters = {
      ...filters,
      writer: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleGenreChange = (selectedOptions) => {
    let newFilters;
    if (selectedOptions.length === 0) {
      newFilters = {
        ...filters,
        genre: []
      };
    }
    else {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      newFilters = {
        ...filters,
        genre: [lastOption.value]
      };
    }
    if(newFilters.genre.length > 0) {
      posthog.capture('apply_filter_genre', { "values": GENRES[newFilters.genre[0]]});
    }
    setFilters(newFilters);
  };

  const handleYearChange = (selectedOptions) => {
    let newFilters;
    if (selectedOptions.length === 0) {
      newFilters = {
        ...filters,
        year: []
      };
    }
    else {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      newFilters = {
        ...filters,
        year: [lastOption.value]
      };
    }
    if(newFilters.year.length > 0) {
      posthog.capture('apply_filter_year', { "values": newFilters.year});
    }
    setFilters(newFilters);
  };

  return (
    <div className="filter-container">
      <div 
        className={`filter-panel-header ${hasActiveFilters ? 'has-active-filters' : ''}`}
        onClick={toggleFilters}
        tabIndex="0"
        role="button"
        aria-expanded={isExpanded}
      >
        <div className="filter-icon-container">
          <IoFilterOutline color="white" />
        </div>
        <span className="filter-text">
          {hasActiveFilters 
            ? `${[
              ...filters.actors, 
              ...filters.director, 
              ...filters.writer, 
              ...filters.year, 
              ...filters.genre.map(genreId => GENRES[genreId])
            ].filter(x => x).join('-')} movies`
            : 'Apply Filters'}
        </span>
        {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
      </div>

      {isExpanded && (
        <div className="filters">
          {/* <div className="select-container">
            <Select styles={customStyles}
              isMulti
              name="genre"
              options={GENRE_IDS.map(genre => ({ value: genre, label: GENRES[genre] }))}
              value={filters.genre.map(genre => ({ value: genre, label: GENRES[genre] }))}
              onChange={handleGenreChange}
              placeholder="Genres..."
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div> */}
          
          <div className="select-container">
            <Select styles={customStyles}
              isMulti
              name="actors"
              options={ACTORS.map(actor => ({ value: actor, label: actor }))}
              value={filters.actors.map(actor => ({ value: actor, label: actor }))}
              onChange={handleActorChange}
              placeholder="Actors..."
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          
          <div className="select-container">
            <Select styles={customStyles}
              isMulti
              name="director"
              options={DIRECTORS.map(director => ({ value: director, label: director }))}
              value={filters.director.map(director => ({ value: director, label: director }))}
              onChange={handleDirectorChange}
              placeholder="Directors..."
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>

          <div className="select-container">
            <Select styles={customStyles}
              isMulti
              name="writer"
              options={WRITERS.map(writer => ({ value: writer, label: writer }))}
              value={filters.writer.map(writer => ({ value: writer, label: writer }))}
              onChange={handleWriterChange}
              placeholder="Writers..."
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>

          

          <div className="select-container">
            <Select styles={customStyles}
              isMulti
              name="year"
              options={[
                { value: "Before 1980", label: "Before 1980" },
                { value: "1980-1990", label: "1980-1990" },
                { value: "1991-2000", label: "1991-2000" },
                { value: "2001-2010", label: "2001-2010" },
                { value: "After 2010", label: "After 2010" }
              ]}
              value={filters.year.map(year => ({ value: year, label: year }))}
              onChange={handleYearChange}
              placeholder="Year..."
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;