// src/MovieCard.js

import React from 'react';
import { IoCloseOutline } from "react-icons/io5";
import './MovieCard.css';

const MovieCard = ({ movie, onClick, onRemove }) => {
  if (!movie) {
    return null; // or return a placeholder card
  }
  
  let posterUrl;
  if (movie.poster_path) {
    if (movie.poster_path.startsWith('https')) {
      posterUrl = movie.poster_path;
    } else {
      posterUrl = `https://image.tmdb.org/t/p/w500${movie.poster_path}`;
    }
  } else {
    posterUrl = `https://dummyimage.com/500x750/646464/ffffff&text=${movie.name}`;
  }

  const handleRemoveClick = (e) => {
    e.stopPropagation(); // Prevent triggering the card's onClick
    onRemove();
  };

  const handleClick = (e) => {
    e.currentTarget.focus(); // Ensure the element maintains focus
    onClick();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.currentTarget.focus(); // Ensure the element maintains focus
      onClick();
    }
  };

  return (
    <div 
      className="movie-card" 
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex="0"
      role="button"
      aria-label={`Play ${movie.name || 'movie'}`}
    >
      <div className="poster-container">
        {onRemove && (
          <button className="remove-button" onClick={handleRemoveClick}>
            <IoCloseOutline />
          </button>
        )}
        <img src={posterUrl} alt={movie.name || 'Movie poster'} />
      </div>
    </div>
  );
};

export default MovieCard;