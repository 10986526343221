import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MovieGrid from '../MovieGrid';
import { API_BASE_URL } from '../constants';
import { IoArrowBackOutline, IoHeartOutline } from "react-icons/io5";
import './WatchLaterPage.css';

const WatchLaterPage = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWatchLaterMovies = async () => {
      setLoading(true);
      setError(null);
      const movieIds = JSON.parse(localStorage.getItem('watchLaterMovies') || '[]');
        if (movieIds.length === 0) {
            setMovies([]);
            setLoading(false);
            return;
        }

      try { 
        const response = await fetch(`${API_BASE_URL}/movies?ids=${movieIds.join(',')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch watch later movies');
        }
        const data = await response.json();
        setMovies(data.movies);
      } catch (error) {
        console.error('Error fetching watch later movies:', error);
        setError('Failed to load watch later movies. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchWatchLaterMovies();
  }, []);

  const handleMovieSelect = (movie, index) => {
    navigate(`/movie/${movie.tmdb_id}`, {
      state: { 
        movies: movies.map(m => ({ tmdb_id: m.tmdb_id })),
        currentIndex: index
      }
    });
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="watch-later-page">
      <div className="page-header">
        <span onClick={handleBack} className="back-button">
          <IoArrowBackOutline />
        </span>
        <h1>Watch Later</h1>
      </div>
      {loading ? (<p>Loading...</p>) : 
      error ? (<p>{error}</p>) 
      :  movies.length > 0 ? (<MovieGrid movies={movies} onMovieSelect={handleMovieSelect} />)
      : (
        <div className="watch-later-no-movies-message">
          <p>No movies in your watch later list</p>
          <p>Add movies by clicking on <IoHeartOutline /> icon on movie page</p>
        </div>
      )}
    </div>
  );
};

export default WatchLaterPage;